import React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import logo from "./static/logo-color.png";
import screenshot1 from "./static/Connected-min.jpg";
import screenshot2 from "./static/Locations-1.jpeg";
import screenshot3 from "./static/Streaming-min.jpg";
import screenshot4 from "./static/Gaming-min.jpg";
import screenshot5 from "./static/Internet-min.jpg";
	

function App() {
  return (
    <div className="App">
       <Helmet>
        <meta
          name="keywords"
          content="Asterisks VPN, no-log VPN, secure browsing, Vless protocol, X-ray, express vpn, Reality protocol, unlimited bandwidth, global VPN access, mobile security, stealth VPN, high-speed VPN, iOS VPN, Android VPN, privacy-first VPN, VPN for streaming, VPN,vpn, VPN encryption, nordvpn,vpnify,proton,wireguard,free,super,proxy,adblock,gpt,youtube,netflix,sky,sport,best,ipv6,chatgpt"
        />
      </Helmet>
      <header className="app__header container">
        <div className="app__logo-wrapper">
          <img
            className="app__logo"
            src={logo}
            alt="Asterisks: VPN No Limit, No Control"
          />
        </div>
        <div className="app__infos">
          <h1 className="app__name">Asterisks VPN: No Limit, No Control</h1>
          <p className="app__description"><b>Unlimited, Safe Streaming: TV, Videos & Beyond</b></p>
          <div className="app__buttons">
              <p className="app__description" ><b> Coming soon for iOS and Android</b></p>
          </div>
        </div>
      </header>

      <section className="app__screenshots container">
        <h2 className="app__section-title">Screenshots</h2>
        <div className="app__screenshots-list">
          <img
            src={screenshot1}
            className="app__screenshot"
            alt="Screenshot 1"
          />
          <img
            src={screenshot2}
            className="app__screenshot"
            alt="Screenshot 2"
          />
          <img
            src={screenshot3}
            className="app__screenshot"
            alt="Screenshot 3"
          />
          <img
            src={screenshot4}
            className="app__screenshot"
            alt="Screenshot 4"
          />
          <img
            src={screenshot5}
            className="app__screenshot"
            alt="Screenshot 4"
          />
        </div>
      </section>

      <section className="app__description-section container">
        <h2 className="app__section-title">Description</h2>
        <div className="app__description-content">
          <p>
            <b>VPN service with no limits and no control</b> 

          </p>
          <p>
            Works in <b>VPN-blocked countries!</b> Don't let anyone restrict
            you!
          </p>
          <p>
            <b>Be anonymous, hide your IP, and protect your privacy.</b> For
            iPhone and Android.
          </p>
          <ul>
            <li>
              <b>No registration needed,</b> just tap connect.
            </li>
            <li>
              <b>15+ worldwide locations. Unlimited</b> speed, bandwidth, and
              devices.
            </li>
            <li>
              Connect from anywhere with automatic{" "}
              <b>stealth mode and bypassing firewalls.</b>
            </li>
    
            <li>
              Watch all of your <b>favorite TV shows</b> through geo-unblocking
              streaming servers.
            </li>
            <li>
              Also works with{" "}
              <b>Netflix, YouTube, Disney+, HBO Max, Amazon Prime Video,</b> and
              many more.
            </li>
            <li>
              <b>No logs,</b> no data collection, always active encryption.
            </li>
            <li>
              <b>Vless with Reality</b>,stateless lightweight transport
              protocol,eliminates the TLS fingerprint of the server, while
              preserving perfect forward secrecy and preventing certificate
              chain attacks
            </li>
            <li>
              Independent and protected by <b>strong privacy laws.</b>
            </li>
          </ul>
        </div>
      </section>

      <section className="app__fulldescription app__section container">
        <h2 className="app__section-title">Help & FAQ</h2>
        <div className="app__fulldescription-content">
          <details className="app__help">
            <summary className="app__help__question">
              <b>What is a VPN?</b>
            </summary>
            A VPN, or Virtual Private Network, is a technology that allows you
            to create a secure and encrypted connection over a less secure
            network, such as the internet. It helps protect your online privacy
            and data by masking your IP address and encrypting your internet
            traffic.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>Why should I use VPN?</b>
            </summary>
            There are several reasons to use a VPN, including: protecting your
            online privacy and data from hackers and snoopers, accessing
            geo-restricted content and websites, blocking online ads and
            malware, bypassing internet censorship and restrictions, securing
            your connection when using public WiFi networks, and preventing your
            internet service provider from tracking and selling your online
            activities.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>Why AsterisksVPN in particular?</b>
            </summary>
            We aim to provide an independent VPN service that is not controlled
            by large corporations. My VPN is fast, reliable, and offers a simple
            user interface. It supports streaming services while ensuring
            privacy. We do not bloat the app with unnecessary features or data
            collection. Studies have indicated that many VPN
            providers collect data despite their promises to protect privacy.
            This can be confirmed by reading the privacy section of a VPN
            service's description in the App Store or Play Store.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>Who operates this VPN service?</b>
            </summary>
            We are independent cyber security engineers who operates this VPN
            service. We have personally programmed the app and manage the
            servers.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>When should I use VPN?</b>
            </summary>
            A VPN is best used when on vacation or traveling, when wanting to
            watch foreign streaming services, access your home services when
            abroad, or bypass network restrictions in school, university or
            work. You're free though to always have an added layer of privacy
            and protection.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>Is using a VPN legal?</b>
            </summary>
            Yes, using a VPN is legal in most countries. However, it is
            important to adhere to the laws and regulations of the country you
            are in when using a VPN service. Note that illegal activities using
            a VPN are and will still be illegal.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>
                My streaming service isn't working or is being blocked. I see
                video ads on YouTube and other platforms.
              </b>
            </summary>
            If your streaming service isn't working, try connecting to a
            streaming server location. This often unblocks most streaming
            services and also blocks most video ads. For YouTube specifically,
            you can connect to the location 'Albania', which doesn't show any
            YouTube ads. If the issue persists, please contact support to see if
            we can resolve the problem together.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>
                How do I enable stealth mode? What encryption is being used?
              </b>
            </summary>
            Stealth mode is automatically enabled for all users and on every
            connection, irrespective of your selected settings. This technology
            ensures that VPN traffic remains invisible to your internet service
            provider. It utilizes combined secure features of the Vless and
            Reality protocol.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>
                How can I check the speed of the connection or check for leaks?
              </b>
            </summary>
            You can utilize various online services to measure the speed of your
            connection, such as fast.com or speedtest.net.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>How fast are the servers? Do you use virtual locations? </b>
            </summary>
            Most of the secure Linux servers have a connection speed between 1
            and 10 Gbit/s. All servers are physically located in the respective
            country, there are no virtual locations.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>Why do you only offer Vless on top of Reality protocol?</b>
            </summary>
            VLESS is a lightweight protocol that bridges Xray clients and
            servers using UUID for authentication, independent of system time.{" "}
            <p></p>
            Reality replaces TLS by eliminating detectable fingerprints,
            maintaining forward secrecy, and preventing certificate chain
            attacks, offering more security and convenience without needing
            domain names or TLS servers.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>
                Are other platforms such as Android, Windows, and Linux
                supported?
              </b>
            </summary>
            Currently, We do support android and iOS mobile platform. Support
            for other platforms is on roadmap based on users / customers
            response.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>
                Can this VPN be configured on other devices, such as my router?
              </b>
            </summary>
            No. Due to the way users are authenticated, only the available apps
            and devices are supported.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>Do you log any data?</b>
            </summary>
            <b>Security by privacy: we ensure no logs are stored.</b>
            We have disabled all logging functions on all servers under my control.
            Furthermore, the app does not involve any data collection or analytics through third parties.
            These measures contribute to the fast startup time and small size of the app. For more detailed information, please check the terms of
            service and privacy policy.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>
                How to share a subscription or purchase between Apple and
                Google/Android Devices?
              </b>
            </summary>
            To link your subscription or purchase between the App Store and the
            Play Store, follow these steps: 
            <li>Open the premium section of the
            app on the device where you made the purchase.</li> <li>Click on 'Show or
            change ID.'</li> <li>Enter a User ID (minimum 10 characters, preferably not
            an email address).</li> <li>Use this same User ID on all other devices
            where you want to access the subscription or purchase.</li> 
            Attention:
            Sharing your User ID with friends or strangers is not allowed. This
            is checked regularly, and misuse will lead to your subscription or
            purchase being deleted.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>I have a billing-related issue. I would like a refund.</b>
            </summary>
            Most purchasing issues can be resolved by reinstalling the app and
            clicking on 'Restore purchases' within the Premium section of the
            app. Additionally, please ensure that you are using the same Apple
            ID for the App Store and the same Google ID for the Play Store
            across all of your devices. If you continue to experience issues or
            would like a refund, please contact Apple or Google Support
            directly, as all purchases are made through the App Store or Play
            Store and I do not have access to them.
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>How to activate family sharing?</b>
            </summary>
            To share your subscription or purchase with another family member,
            enable family sharing and share the AsterisksVPN subscription or
            purchase. More information can be found on{" "}
            <a href="https://support.apple.com/en-us/108774">
              Apple's official website
            </a>{" "}
            or{" "}
            <a href="https://support.google.com/googleplay/answer/7007852">
              Google's official website
            </a>
            .
          </details>
          <br />
          <details className="app__help">
            <summary className="app__help__question">
              <b>My question still isn't answered.</b>
            </summary>
            Please don't hesitate to contact me through the feedback button
            located under the Premium section or by using the{" "}
            <a href="https://tally.so/r/3NLDNG" target="_blank" rel="noopener noreferrer">
              support form
            </a>{" "}
            on my website. I will respond to your inquiry as promptly as
            possible. Thank you!
          </details>
        </div>
      </section>

      <section className="app__fulldescription app__section container">
        <h2 className="app__section-title">VPN Locations</h2>
        <div className="app__fulldescription-content">
          <ul>
            <li>
              Albania<span translate="no"> 🇦🇱</span> Argentina
              <span translate="no"> 🇦🇷</span>
            </li>
            <li>
              Australia<span translate="no"> 🇦🇺</span> Austria
              <span translate="no"> 🇦🇹</span>
            </li>
            <li>
              Belgium<span translate="no"> 🇧🇪</span> Brazil
              <span translate="no"> 🇧🇷</span>
            </li>
            <li>
              Canada<span translate="no"> 🇨🇦</span> Colombia
              <span translate="no"> 🇨🇴</span>
            </li>
            <li>
              Cyprus<span translate="no"> 🇨🇾</span> France
              <span translate="no"> 🇫🇷</span>
            </li>
            <li>
              Germany<span translate="no"> 🇩🇪</span> Hong Kong
              <span translate="no"> 🇭🇰</span>
            </li>
            <li>
              India<span translate="no"> 🇮🇳</span> Indonesia
              <span translate="no"> 🇮🇩</span>
            </li>
            <li>
              Japan<span translate="no"> 🇯🇵</span> Malaysia
              <span translate="no"> 🇲🇾</span>
            </li>
            <li>
              Mexico<span translate="no"> 🇲🇽</span> Morocco
              <span translate="no"> 🇲🇦</span>
            </li>
            <li>
              Netherlands<span translate="no"> 🇳🇱</span> Norway
              <span translate="no"> 🇳🇴</span>
            </li>
            <li>
              Portugal<span translate="no"> 🇵🇹</span> Romania
              <span translate="no"> 🇷🇴</span>
            </li>
            <li>
              Russia<span translate="no"> 🇷🇺</span> Singapore
              <span translate="no"> 🇸🇬</span>
            </li>
            <li>
              South Africa<span translate="no"> 🇿🇦</span> South Korea
              <span translate="no"> 🇰🇷</span>
            </li>
            <li>
              Spain<span translate="no"> 🇪🇸</span> Sweden
              <span translate="no"> 🇸🇪</span>
            </li>
            <li>
              Switzerland<span translate="no"> 🇨🇭</span> Taiwan
              <span translate="no"> 🇹🇼</span>
            </li>
            <li>
              United Arab Emirates<span translate="no"> 🇦🇪</span> United Kingdom
              <span translate="no"> 🇬🇧</span>
            </li>
            <li>
              United States <span translate="no"> 🇺🇸</span>
            </li>
          </ul>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="footer__container">
            <p className="footer__love">
              &copy;{new Date().getFullYear()} All rights reserved
              <br />
              Asterisks VPN
            </p>
            <ul className="footer__links">
              <a
                href="https://tally.so/r/3NLDNG"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>{" "}
              &nbsp;&nbsp;&nbsp;
              <a
                href="https://stats.uptimerobot.com/miI48VQT9t"
                target="_blank"
                rel="noopener noreferrer"
              >
                Service status
              </a>{" "}
              &nbsp;&nbsp;&nbsp;
              <a
                href="https://privacy.asterisksvpn.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use (Coming Soon)
              </a>{" "}
              &nbsp;&nbsp;&nbsp;
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
